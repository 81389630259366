




























































































































































































































import Vue from 'vue'

import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BLink, BButton, BBadge,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { filters } from '@core/mixins/filters'

import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { appEmailAuditList } from '@/store/modules/app-email-audit-list'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'EmailAuditList',
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BLink,
    BButton,
    flatPickr,

    vSelect,

    TableSpinner,
  },
  mixins: [filters],
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
    }
  },
  computed: {
    tableColumns() {
      return appEmailAuditList.getters.getTableColumns
    },
    perPage() {
      return appEmailAuditList.getters.getPerPage
    },
    totalItems() {
      return appEmailAuditList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appEmailAuditList.getters.getCurrentPage
      },
      set(val: number) {
        appEmailAuditList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appEmailAuditList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appEmailAuditList.getters.getSearchQuery
      },
      set(val: string) {
        appEmailAuditList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    filterTimestamp: {
      get() {
        return appEmailAuditList.getters.getFilterTimestamp
      },
      set(val: string) {
        appEmailAuditList.mutations.SET_FILTER_TIMESTAMP(val)
      },
    },
    filterTrigger: {
      get() {
        return appEmailAuditList.getters.getFilterTrigger
      },
      set(val: string) {
        appEmailAuditList.mutations.SET_FILTER_TRIGGER(val)
      },
    },
    filterUserType: {
      get() {
        return appEmailAuditList.getters.getFilterUserType
      },
      set(val: string) {
        appEmailAuditList.mutations.SET_FILTER_USER_TYPE(val)
      },
    },
    sortBy() {
      return appEmailAuditList.getters.getSortBy
    },
    isSortDirDesc() {
      return appEmailAuditList.getters.getIsSortDirDesc
    },
    filterTriggerOptions() {
      return appEmailAuditList.getters.getFilterTriggerOptions
    },
    filterUserTypeOptions() {
      return appEmailAuditList.getters.getFilterUserTypeOptions
    },
    dataMeta() {
      return appEmailAuditList.getters.getDataMeta
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }

      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
    filterTimestamp(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterTimestamp', val)
      }
    },
    filterTrigger(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterTrigger', val)
      }
    },
    filterUserType(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterUserType', val)
      }
    },
  },
  async created() {
    appEmailAuditList.actions.fetchUserType()
    appEmailAuditList.actions.fetchTriggers()
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val: number) {
      appEmailAuditList.mutations.SET_PER_PAGE(val)
    },
    fetchEmailAuditList() {
      return appEmailAuditList.actions.fetchEmailAuditList()
    },
    updateTable() {
      (this.$refs.refEmailAuditListTable as BTable).refresh()
    },
    clearFilters() {
      appEmailAuditList.mutations.CLEAR_FILTERS()
    },
    setFilterParams() {
      appEmailAuditList.mutations.SET_FILTER_PARAMS()
    },
  },
})
