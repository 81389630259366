import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { EmailAuditListState, IEmailAuditListRequest } from './types'
import { fetchEmailAuditList, fetchTriggers, fetchUserType } from '@/api/email'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appEmailAuditList = createModule(
  'appEmailAuditList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'recipient', label: 'Recipient Email', sortable: true },
        { key: 'subject', label: 'subject', sortable: true },
        { key: 'user_type', label: 'User Type', sortable: true },
        { key: 'trigger', label: 'trigger', sortable: true },
        { key: 'timestamp', label: 'timestamp', sortable: true },
        { key: 'status', label: 'status', sortable: true },
      ],
      perPage: Number(localStorage.getItem('emailAuditPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '',
      filterTimestamp: '',
      filterTrigger: '',
      filterUserType: '',
      sortBy: 'created_at',
      isSortDirDesc: true,
      filterTriggerOptions: [],
      filterUserTypeOptions: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
    } as EmailAuditListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getFilterTimestamp: state => state.filterTimestamp,
      getFilterTrigger: state => state.filterTrigger,
      getFilterUserType: state => state.filterUserType,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getFilterTriggerOptions: state => state.filterTriggerOptions,
      getFilterUserTypeOptions: state => state.filterUserTypeOptions,
      getDataMeta: state => state.dataMeta,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('emailAuditPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_FILTER_TIMESTAMP(state, val: string) {
        state.filterTimestamp = val
      },
      SET_FILTER_TRIGGER(state, val: string) {
        state.filterTrigger = val
      },
      SET_FILTER_USER_TYPE(state, val: string) {
        state.filterUserType = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_FILTER_TRIGGER_OPTIONS(state, val: string[]) {
        state.filterTriggerOptions = val
      },
      SET_FILTER_USER_TYPE_OPTIONS(state, val: string[]) {
        state.filterUserTypeOptions = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      CLEAR_FILTERS(state) {
        state.filterTimestamp = ''
        state.filterTrigger = ''
        state.filterUserType = ''
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('emailAuditPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
        state.filterTimestamp = query.filterTimestamp || ''
        state.filterTrigger = query.filterTrigger || ''
        state.filterUserType = query.filterUserType || ''
      },
    },
    actions: {
      async fetchEmailAuditList({ state }) {
        try {
          const queryParams: IEmailAuditListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
          }
          if (state.searchQuery) queryParams.search = state.searchQuery
          if (state.filterTimestamp) queryParams.createdAt = state.filterTimestamp
          if (state.filterTrigger) queryParams.trigger = state.filterTrigger
          if (state.filterUserType) queryParams.userType = state.filterUserType
          const response:any = await fetchEmailAuditList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appEmailAuditList.mutations.SET_TOTAL_ITEMS(total)
          appEmailAuditList.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching email audits' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async fetchUserType() {
        const filterUserTypeOptions = await fetchUserType().then(response => response.data.data.filter((name: string) => name !== null))
        appEmailAuditList.mutations.SET_FILTER_USER_TYPE_OPTIONS(filterUserTypeOptions)
      },
      async fetchTriggers() {
        const filterTriggerOptions = await fetchTriggers().then(response => response.data.data.filter((trigger: string) => trigger !== null))
        appEmailAuditList.mutations.SET_FILTER_TRIGGER_OPTIONS(filterTriggerOptions)
      },
    },
  },
)

appEmailAuditList.register(store)
